module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"Silico","name":"Silico - Specjaliści w przetwórstwie silikonów","description":"Silico Sp. z o.o. jest firmą polską specjalizującą się w produkcji wyrobów z gumy silikonowej oraz elastomerów termoplastycznych.","lang":"pl","start_url":"/","background_color":"#525252","theme_color":"#525252","display":"minimal-ui","icon":"src/assets/images/favicon.png","localize":[{"start_url":"/en/","lang":"en","name":"Silico - Experts in silicone processing","short_name":"Silico","description":"Silico Sp. z o.o. is a Polish company specializing in the manufacturing of silicone rubber and thermoplastic elastomer products."},{"start_url":"/de/","lang":"de","name":"Silico - Experten für Silikonverarbeitung","short_name":"Silico","description":"Silico Sp. z o.o ist ein polnisches Unternehmen, das sich auf die Herstellung von Erzeugnissen aus Silikonkautschuk und aus thermoplastischen Elastomeren spezialisiert hat."},{"start_url":"/ru/","lang":"ru","name":"Silico - Специалисты по переработке силиконов","short_name":"Silico","description":"Silico Sp. z o.o. это польская фирма, специализирующаяся в производстве изделий из силиконовой резины и термопластичных эластомеров."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"46059b510ba380a50e4752aa4f2d0fa3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"C:\\Users\\Maciej\\Code\\silico/src/assets/translations","languages":["pl","en","de","ru"],"defaultLanguage":"pl","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-MWD44B63DY"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"delayOnRouteUpdate":0,"exclude":[],"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
